<template>
  <div>
    <el-carousel height="40vh">
      <el-carousel-item v-for="item in swiperList" :key="item.id">
        <!-- <div class="carouselImgBox"> -->
          <img
          :src="$src+item.imgUrl"
          alt=""
          style="width: 100%;height:100%;object-fit:contain"
        />
        <!-- </div> -->
      </el-carousel-item>
    </el-carousel>
    <div
      class="container showPage"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      v-loading="loading"
    >
      <!-- 第一行 -->
      <div class="oneLine" v-if="show">
        <!-- 通知公告 -->
        <div class="oneLineOne" v-if="IndexSonMenu[0]">
          <indexItemHeader
            :logoUrl="IndexSonMenu[0].menuIcon||''"
            :title="IndexSonMenu[0].menuName"
            :ids="IndexSonMenu[0]"
          ></indexItemHeader>
          <div class="noticeBox" v-if="IndexSonMenu[0]&&IndexSonMenu[0].articleList&&IndexSonMenu[0].articleList.length>0">
            <div
              class="noticeLine"
              v-for="item in IndexSonMenu[0].articleList"
              :key="item"
              @click="goArticleDetails(IndexSonMenu[0].id, item.id)"
            >
              <i class="el-icon-arrow-right"></i>
              <div class="names">
                {{ item.articleTitle }}
              </div>
            </div>
          </div>
        </div>
        <!-- 要闻速递 -->
        <div class="oneLineTwo" v-if="IndexSonMenu[1]">
          <indexItemHeader
            :logoUrl="IndexSonMenu[1].menuIcon||''"
            :title="IndexSonMenu[1].menuName"
            :ids="IndexSonMenu[1]"
          ></indexItemHeader>
          <div
            class="carouselBox"
            v-if="IndexSonMenu[1].list && IndexSonMenu[1].list.length > 0"
          >
            <div class="imgs">
              <el-carousel
                height="146px"
                :autoplay="false"
                @change="carouselChange"
              >
                <el-carousel-item
                  v-for="(item, index) in IndexSonMenu[1].list"
                  :key="index"
                >
                  <div class="imgbox">
                    <img
                      @click.stop="
                        goArticleDetails(IndexSonMenu[1].id, item.id)
                      "
                      style="width: 100%; height: 100%;  transition: all 0.5s;"
                      :src="$src + item.titleImage"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div
              class="infos"
              @click.stop="
                goArticleDetails(
                  IndexSonMenu[1].id,
                  IndexSonMenu[1].list[carcouseIndx].id
                )
              "
            >
              <div
                class="infosTitle"
                v-if="IndexSonMenu[1].list && IndexSonMenu[1].list.length > 0"
              >
                {{ IndexSonMenu[1].list[carcouseIndx].articleTitle }}
                <!-- {{carcouseIndx}} -->
              </div>
              <div
                class="description"
                v-if="IndexSonMenu[1].list && IndexSonMenu[1].list.length > 0"
              >
                {{
                  getText(IndexSonMenu[1].list[carcouseIndx].valueContent) || ""
                }}
              </div>
            </div>
          </div>
          <div
            v-if="IndexSonMenu[1].list.length == 0"
            style="font-weight: 700; padding: 170px 0;text-align:center"
          >
            暂无内容~
          </div>
          <div class="noticeBox">
            <div
              class="noticeLine"
              @click.stop="goArticleDetails(IndexSonMenu[1].id, item.id)"
              v-for="item in IndexSonMenu[1].articleList"
              :key="item.id"
            >
              <i class="el-icon-arrow-right"></i>
              <div class="namesBox">
                <div class="names">
                  {{ item.articleTitle }}
                </div>
                <div class="times">
                  {{ parseTime(item.createTime, "{m}-{d}") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 新闻咨询 -->
        <div class="oneLineThree" v-if="IndexSonMenu[2]">
          <indexItemHeader
            :logoUrl="IndexSonMenu[2].menuIcon||''"
            :title="IndexSonMenu[2].menuName"
            :ids="IndexSonMenu[2]"
          ></indexItemHeader>
          <div
            class="threeTile"
            v-if="IndexSonMenu[2].articleList[0]"
            @click.stop="
              goArticleDetails(
                IndexSonMenu[2].id,
                IndexSonMenu[2].articleList[0].id
              )
            "
          >
            {{ IndexSonMenu[2].articleList[0].articleTitle }}
          </div>
          <div class="" v-else style="padding: 170px 155px; font-Weight: 700">
            暂无内容~
          </div>
          <div class="noticeBox">
            <div
              class="noticeLine"
              v-for="item in IndexSonMenu[2].articleList.slice(1, 7)"
              @click.stop="goArticleDetails(IndexSonMenu[2].id, item.id)"
              :key="item"
            >
              <i class="el-icon-arrow-right"></i>
              <div class="namesBox">
                <div class="names">
                  {{ item.articleTitle }}
                </div>
                <div class="times">
                  {{ parseTime(item.createTime, "{m}-{d}") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第二行 -->
      <div class="twoLine" v-if="show">
        <div class="leftBox" v-if="IndexSonMenu[3]">
          <div class="leftBoxTopLine">
            <div class="titlebox">
              <img v-if="IndexSonMenu[3].menuIcon" :src="$src + IndexSonMenu[3].menuIcon" alt="" />
              <h2 class="title">{{ IndexSonMenu[3].menuName }}</h2>
            </div>
            <div class="more" @click="gomore(IndexSonMenu[3])">更多>></div>
          </div>
          <div class="bottomleft">
            <img
              class="showImg"
              @click.stop="
                goArticleDetails(
                  IndexSonMenu[3].id,
                  IndexSonMenu[3].articleList[0].id
                )
              "
              v-if="IndexSonMenu[3] && IndexSonMenu[3].articleList[0]"
              :src="$src + IndexSonMenu[3].articleList[0].titleImage"
              alt=""
            />
            <div
              class="showImg"
              v-else
              style="
                text-align: center;
                padding-top: 130px;
                border: 1px solid #cfcfcf;
                box-sizing: border-box;
                font-Weight:700
              "
            >
              暂无内容~
            </div>
            <div class="videoList">
              <div
                class="videoItem"
                @click.stop="goArticleDetails(IndexSonMenu[3].id, item.id)"
                v-for="item in IndexSonMenu[3].articleList"
                :key="item.id"
              >
                <img src="@/assets/indexHome/video.png" alt="" />
                <div class="rightInfo">
                  <div class="nameA">
                    {{ item.articleTitle }}
                  </div>
                  <div class="times">
                    {{ parseTime(item.createTime, "{y}-{m}-{d}") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="BoxItem" v-if="IndexSonMenu[4]" @click="BoxItemClick(IndexSonMenu[4])">
            <img v-if="IndexSonMenu[4].menuIcon" :src="$src + IndexSonMenu[4].menuIcon" alt="" />
            <div class="names">{{ IndexSonMenu[4].menuName }}</div>
          </div>
          <div class="BoxItem" v-if="IndexSonMenu[5]" @click="BoxItemClick(IndexSonMenu[5])">
            <img v-if="IndexSonMenu[5].menuIcon" :src="$src + IndexSonMenu[5].menuIcon" alt="" />
            <div class="names">{{ IndexSonMenu[5].menuName }}</div>
          </div>
          <div class="BoxItem" v-if="IndexSonMenu[6]" @click="BoxItemClick(IndexSonMenu[6])">
            <img v-if="IndexSonMenu[6].menuIcon" :src="$src + IndexSonMenu[6].menuIcon" alt="" />
            <div class="names">{{ IndexSonMenu[6].menuName }}</div>
          </div>
          <div class="BoxItem" v-if="IndexSonMenu[7]" @click="BoxItemClick(IndexSonMenu[7])">
            <img v-if="IndexSonMenu[7].menuIcon" :src="$src + IndexSonMenu[7].menuIcon" alt="" />
            <div class="names">{{ IndexSonMenu[7].menuName }}</div>
          </div>
        </div>
      </div>
      <!-- 第三行 -->
      <div class="threeLine" v-if="show">
        <div class="threeLineOne" v-if="IndexSonMenu[8]">
          <indexItemHeader
            :logoUrl="IndexSonMenu[8].menuIcon||''"
            :title="IndexSonMenu[8].menuName"
            :ids="IndexSonMenu[8]"
          ></indexItemHeader>
          <div class="articleList">
            <div
              class="articleItem"
              @click.stop="goArticleDetails(IndexSonMenu[8].id, item.id)"
              v-for="item in IndexSonMenu[8].articleList"
              :key="item.id"
            >
              <div class="articleNameLine">
                <i class="el-icon-arrow-right"></i>
                <div class="name">
                  {{ item.articleTitle }}
                </div>
              </div>
              <div class="times">
                {{ parseTime(item.createTime, "{y}-{m}-{d}") }}
              </div>
            </div>
          </div>
          <div
            style="
              padding: 150px 150px;
              box-sizing: border-box;
              font-weight: 700;
            "
            v-if="IndexSonMenu[8].articleList.length == 0"
          >
            暂无内容~
          </div>
        </div>

        <!-- 第三行第二个 -->
        <div class="threeLineOne threeLineTwo" v-if="IndexSonMenu[9]">
          <indexItemHeader
            :logoUrl="IndexSonMenu[9].menuIcon||''"
            :title="IndexSonMenu[9].menuName"
            :ids="IndexSonMenu[9]"
          ></indexItemHeader>
          <div class="articleList">
            <div
              class="articleItem"
              v-for="item in IndexSonMenu[9].articleList"
              @click.stop="goArticleDetails(IndexSonMenu[9].id, item.id)"
              :key="item.id"
            >
              <div class="articleNameLine">
                <i class="el-icon-arrow-right"></i>
                <div class="name">
                  {{ item.articleTitle }}
                </div>
              </div>
              <div class="times">{{ parseTime(item.createTime) }}</div>
            </div>
          </div>
          <div
            style="
              padding: 150px 150px;
              box-sizing: border-box;
              font-weight: 700;
            "
            v-if="IndexSonMenu[9].articleList.length == 0"
          >
            暂无内容~
          </div>
        </div>
        <div class="threeLineOne threeLineThree" v-if="IndexSonMenu[10]">
          <indexItemHeader
            :logoUrl="IndexSonMenu[10].menuIcon||''"
            :title="IndexSonMenu[10].menuName"
            :ids="IndexSonMenu[10]"
          ></indexItemHeader>
          <div class="articleList">
            <div
              class="articleItem"
              @click.stop="goArticleDetails(IndexSonMenu[10].id, item.id)"
              v-for="item in IndexSonMenu[10].articleList"
              :key="item.id"
            >
              <div class="articleNameLine">
                <i class="el-icon-arrow-right"></i>
                <div class="name">
                  {{ item.articleTitle }}
                </div>
              </div>
              <div class="times">{{ parseTime(item.createTime) }}</div>
            </div>
          </div>
          <div
            style="
              padding: 150px 110px;
              box-sizing: border-box;
              font-weight: 700;
            "
            v-if="IndexSonMenu[10].articleList.length == 0"
          >
            暂无内容~
          </div>
        </div>
      </div>
      <!-- 图片集锦 -->
      <div class="photoBox" v-if="IndexSonMenu[11] && show">
        <div class="PhotoTitle">
          <div class="leftLine"></div>
          <div class="title">{{ IndexSonMenu[11].menuName }}</div>
          <div class="rightLine"></div>
        </div>
        <div
          class="carouselBoxA"
          v-if="IndexSonMenu[11].articleList.length > 0"
        >
          <el-carousel height="250px">
            <el-carousel-item
              v-for="(item, i) in IndexSonMenu[11].articleList"
              :key="i"
            >
              <div class="photoList">
                <div
                  v-for="(photoItem, index) in item"
                  @click="goArticleDetails(IndexSonMenu[11].id, photoItem.id)"
                  :key="index"
                  class="photoItem"
                >
                  <div class="imgsbox">
                    <img v-if="photoItem.titleImage" :src="$src + photoItem.titleImage" alt="" />
                    <div class="mask"></div>
                  </div>
                  <div class="textA">{{ photoItem.articleTitle }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          class="carouselBoxA"
          style="padding: 40px 47%; font-weight: 700"
          v-if="IndexSonMenu[11].articleList.length == 0"
        >
          暂无内容~
        </div>
      </div>
      <!-- 超出9个的 -->
      <div class="otherList" v-if="show && twoSonMenu && twoSonMenu.length > 0">
        <div v-for="(item, index) in twoSonMenu" :key="index" class="otherItem">
          <indexItemHeader
            :logoUrl="item.menuIcon||''"
            :title="item.menuName"
            :ids="twoSonMenu[index]"
          ></indexItemHeader>
          <div class="articleList">
            <div
              class="articleItem"
              v-for="(info, index) in item.articleList"
              :key="index"
            >
              <div class="articleNameLine">
                <i class="el-icon-arrow-right"></i>
                <div class="name">
                  {{ info.articleTitle }}
                </div>
              </div>
              <div class="times">
                {{ parseTime(info.createTime, "{y}-{m}-{n}") }}
              </div>
            </div>
          </div>
          <div
            class=""
            style="
              font-weight: 700;
              box-sizing: border-box;
              padding: 120px 0;
              text-align:center
            "
            v-if="item.articleList.length == 0"
          >
            暂无内容~
          </div>
        </div>
      </div>
    </div>
    <div class="loadingBox" v-if="noShow">
      <img src="@/static/img/no.png">
        <div style="margin-top: 10px">暂无内容</div>
    </div>
  </div>
</template>

<script>
import {
  getMenuListTree,
  getTabWebsiteMenuId,
  getTabWebsiteArticleList,
  getWebsiteMenuList,
  tabWebSiteCarouseImg,
} from "@/api/api.js";
import indexItemHeader from "@/components/indexItemHeader.vue";
export default {
  name: "index",
  data() {
    return {
      noticeLogo: require("@/assets/indexHome/player.png"),
      IndexSonMenu: [],
      twoSonMenu: [],
      carcouseIndx: 0,
      loading: false,
      swiperList: [],
      show: false,
      noShow:false
    };
  },
  created() {
    this.getMenuListTree();
    this.tabWebSiteCarouseImg();
  },
  methods: {
    gomore(item) {
      if(item.outsideLink==1){
        window.open(item.menuUrl)
      }else{
        this.$router
        .push({ path: "/home", query: { id: item.id } })
        .catch((e) => e);
      }

    },
    // 获取轮播图列表
    tabWebSiteCarouseImg() {
      tabWebSiteCarouseImg({pageSize:9999,cloudAllianceId: localStorage.getItem("cloudAllianceId")||''}).then((res) => {
        this.swiperList = res.rows;
      });
    },
    // 第二个轮播图change
    carouselChange(e) {
      this.carcouseIndx = Number(e);
    },
    // 去文章详情
    goArticleDetails(ids, id) {
      this.$router
        .push({ path: "/home", query: { id: ids, textId: id } })
        .catch((e) => e);
    },
    // 获取子菜单列表
    async getMenuListTree() {
      this.noShow = false
      this.loading = true;
      this.show = false;
      let that = this;
      const res = await getMenuListTree({
        pageSize: 9999999,
        cloudAllianceId: localStorage.getItem("cloudAllianceId")||"",
        orderByColumn: "orderSort",
        isAsc: "desc",
      });
      if(res.rows.length==0){
        this.loading = false;
        this.show = false
        this.noShow = true
        return
      }
      if (res.code == 200 && res.rows) {
        getMenuListTree({
          pageSize: 9999,
          supperId: res.rows[0].id,
          cloudAllianceId: localStorage.getItem("cloudAllianceId")||"",
          orderByColumn: 'orderSort',
          isAsc: 'desc'
        }).then((res) => {
          if (res.rows && res.rows.length > 0) {
            let info = null
            res.rows.forEach(async (item, index) => {
              if(index== 1){
                 info = await getTabWebsiteArticleList({websiteMenuId: item.id,cloudAllianceId: localStorage.getItem("cloudAllianceId")||"", orderByColumn:'create_time',
          isAsc:'desc'})
              }else{
                 info = await getWebsiteMenuList({
                websiteMenuId: item.id,
                cloudAllianceId: localStorage.getItem("cloudAllianceId")||""
              });
              }
              if (info&&info.code == 200) {
                if (index == 0) {
                  that.$set(item, "articleList", info.rows.slice(0, 9));
                } else if (index == 1) {
                  that.$set(item, "articleList", info.rows.slice(3, 8));
                  that.$set(item, "list", info.rows.slice(0, 3));
                } else if (index == 2) {
                  that.$set(item, "articleList", info.rows.slice(0, 8));
                } else if (index == 3) {
                  that.$set(item, "articleList", info.rows.slice(0, 4));
                } else if (index == 8) {
                  that.$set(item, "articleList", info.rows.slice(0, 5));
                } else if (index == 9) {
                  that.$set(item, "articleList", info.rows.slice(0, 5));
                } else if (index == 10) {
                  that.$set(item, "articleList", info.rows.slice(0, 5));
                } else if (index == 11) {
                  const list = this.setTwoDimensionalArray(info.rows, 4);
                  that.$set(item, "articleList", list);
                } else {
                  that.$set(item, "articleList", info.rows.slice(0, 5));
                }
              }else{
                that.$set(item, "articleList", []);
              }
            });
            this.IndexSonMenu = res.rows;
            this.loading = false;
            if (res.rows.length > 11) {
              this.twoSonMenu = res.rows.slice(12);
            }
            this.show = true;
            // console.log(this.IndexSonMenu, "子菜单列表");
          }else{
            this.loading = false;
            this.show = false
            this.noShow  = true
          }

        });
      }
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string" && /^[0-9]+$/.test(time)) {
          time = parseInt(time);
        } else if (typeof time === "string") {
          time = time
            .replace(new RegExp(/-/gm), "/")
            .replace("T", " ")
            .replace(new RegExp(/\.[\d]{3}/gm), "");
        }
        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        if (result.length > 0 && value < 10) {
          value = "0" + value;
        }
        return value || 0;
      });
      return time_str;
    },
    // 菜单点击
    BoxItemClick(item){
      // console.log(item);
      if(item.outsideLink==1){
        window.open(item.menuUrl)
      }else{
        this.$router.push({ path:'/home' ,query:{id:item.id} }).catch(e=>e)
      }
    },
    // 获取文字
    getText(string) {
      if (!string) return "";
      const regex = /(<([^>]+)>)|<img[^>]*>|<video[^>]*>/gi;

      // 移除HTML、视频和图片标签，并去除空格
      let textWithoutTagsAndSpaces = string
        .replace(regex, "")
        .replace(/&nbsp;/g, "");

      return textWithoutTagsAndSpaces;
    },
    // 将一维数组转换为二维数组
    setTwoDimensionalArray(list, num) {
      const listResult = []; // 最终返回的二维数组
      const count = num;
      let i = 0;
      let tempArr = [];
      list.forEach((e) => {
        i++;
        tempArr.push(e);
        if (i % count === 0 || list.length === i) {
          listResult.push(tempArr);
          tempArr = [];
        }
      });
      return listResult;
    },
  },
  components: {
    indexItemHeader,
  },
};
</script>

<style lang="scss">
.carouselImgBox{
  height: 380px;
  overflow: hidden;
}
@keyframes show {
  0%{
    opacity: 0.4;
    transform: translateY(-100px);
  }
  50%{
    opacity: 0.7;
    transform: translateY(-60px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}
.showPage{
  // animation: show 2s linear;

}
.loadingBox{
  width: 130px;
  // border: 1px solid red;
  margin: 100px auto;
  text-align: center;
  img{
    width: 150px;
    height: 150px;
  }
}
.container {
  width: 1200px;
  margin: 30px auto;
  min-height: 200px;
  .noticeLine {
    cursor: pointer !important;
    &:hover {
      color: #800000;
    }
  }
  .articleNameLine {
    cursor: pointer !important;
    &:hover {
      .name {
        color: #800000 !important;
      }
    }
  }
}
.oneLine {
  display: flex;
  // justify-content: space-between;
  .oneLineOne {
    width: 320px;
    height: 476px;
    border: 1px solid #cfcfcf;
    .noticeBox {
      padding: 10px 20px;
    }
    .noticeLine {
      display: flex;
      align-items: center;
      margin: 20px 0;
      .el-icon-arrow-right {
        font-size: 15px;
      }
      .names {
        margin-left: 6px;
        font-size: 15px;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .oneLineTwo {
    margin-left: 20px;
    width: 417px;
    height: 476px;
    border: 1px solid #cfcfcf;
    // 第一行要闻速递
    .carouselBox {
      padding: 20px 17px 0px;
      display: flex;
      .imgs {
        width: 188px;
        height: 100%;
        .imgbox {
          height: 146px;
          overflow: hidden;
          cursor: pointer;
          &:hover {
            img {
              transform: scale(1.2);
              transition: all 0.5s;
            }
          }
        }
        .el-carousel__button {
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
      .infos {
        cursor: pointer;
        width: calc(100% - 188px);
        padding-left: 10px;
        height: 146px;
        &:hover{
          color: #800000;
        }
        .infosTitle {
          font-size: 19px;
          font-weight: 700;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .description {
          margin-top: 10px;
          line-height: 24px;
          font-size: 15px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .noticeBox {
      padding: 10px 20px;
    }
    .noticeLine {
      // box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-top: 10.5px;
      padding-bottom: 11px;

      &:not(:last-child) {
        border-bottom: 1px solid #cfcfcf;
      }
      .el-icon-arrow-right {
        font-size: 15px;
      }
      .namesBox {
        width: 95%;
        box-sizing: border-box;
        margin-left: 6px;
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .names {
          width: 78%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .times {
          color: #999;
        }
      }
    }
  }
  .oneLineThree {
    margin-left: 20px;
    width: 417px;
    height: 476px;
    border: 1px solid #cfcfcf;
    .threeTile {
      font-weight: 700;
      font-size: 20px;
      padding: 20px 35px 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid #cfcfcf;
    }
    .noticeBox {
      padding: 0px 17px 10px;
    }
    .noticeLine {
      display: flex;
      align-items: center;
      margin: 21px 0;
      .el-icon-arrow-right {
        font-size: 15px;
      }
      .namesBox {
        width: 95%;
        margin-left: 6px;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        .names {
          width: 74%;
          padding-right: 20px;
          width: 74%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .times {
          color: #999;
        }
      }
    }
  }
}
// 第二行
.twoLine {
  margin-top: 30px;
  display: flex;
  .leftBox {
    width: 855px;
    .leftBoxTopLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-top: 2px solid #800000;
      height: 75px;
      line-height: 75px;
      .titlebox {
        display: flex;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
          border-radius: 50%;
        }
        .title {
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 5px;
          font-size: 23px;
        }
      }
      .more {
        cursor: pointer;
        font-size: 15px;
        color: #666;
      }
    }
    .bottomleft {
      display: flex;
      justify-content: space-between;
      .showImg {
        cursor: pointer;
        width: 420px;
        height: 288px;
      }
      .videoList {
        width: 420px;
        padding-left: 20px;
        .videoItem {
          border-bottom: 1px dashed #cfcfcf;
          font-size: 16px;
          padding: 0 0 12px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          .rightInfo {
            width: 90%;
            cursor: pointer;
            &:hover {
              .nameA {
                color: #800000;
              }
            }
            .nameA {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .times {
              display: inline-block;
              margin-top: 5px;
              color: #666;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .rightBox {
    margin-left: 25px;
    width: 320px;
    height: 360px;
    // overflow: auto;
    .BoxItem {
      cursor: pointer;
      width: 100%;
      height: 82px;
      color: #fff;
      display: flex;
      align-items: center;
      padding-left: 35px;
      background: #800000;
      box-sizing: border-box;
      margin-bottom: 10px;
      &:hover{
        img{
          transform: rotateY(180deg);
          transition: all 0.5s;
        }
      }
      img {
        transition: all 0.5s;
        width: 42px;
        height: 42px;
      }
      .names {
        width: calc(100% - 60px);
        padding-left: 10px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
//  第三行
.threeLine {
  margin: 30px 0;
  display: flex;
  .threeLineOne {
    width: 416px;
    height: 477px;
    border: 1px solid #cfcfcf;
    .articleList {
      padding: 20px;
      .articleItem {
        margin-bottom: 15px;
        padding-bottom: 15px;
        &:not(:last-child) {
          border-bottom: 1px dashed #cfcfcf;
        }
        .articleNameLine {
          display: flex;
          align-items: center;
          .el-icon-arrow-right {
            font-size: 18px;
          }
          .name {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 10px;
            color: #333;
            font-size: 16px;
          }
        }
        .times {
          padding: 5px 0 0;
          color: #666;
          font-size: 15px;
          margin-left: 32px;
        }
      }
    }
  }
  .threeLineTwo {
    margin-left: 20px;
  }
  .threeLineThree {
    width: 322px;
    height: 477px;
    margin-left: 20px;
  }
}
// 图片集锦
.photoBox {
  .PhotoTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftLine {
      width: 44%;
      height: 2px;
      background: #800000;
    }
    .rightLine {
      width: 44%;
      height: 2px;
      background: #800000;
    }
    .title {
      font-weight: 700;
      font-size: 24px;
      text-align: center;
    }
  }
  .carouselBoxA {
    width: 1175px;
    margin: 30px auto 40px;
    .el-carousel__button {
      // width: 6px;
      // height: 6px;
      // border-radius: 50%;
      display: none;
    }
    .photoList {
      display: flex;
      :not(:first-of-type) {
        margin-left: 28px;
      }
      .photoItem {
        cursor: pointer;
        width: 270px;
        height: 100%;
        overflow: hidden;
        &:hover {
          .textA {
            color: #800000 !important;
          }
        }
        .imgsbox {
          width: 270px;
          height: 200px;
          overflow: hidden;
          position: relative;
          .mask{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($color: #000, $alpha: 0.3);
          }
          &:hover {
            img {
              transform: scale(1.1);
              transition: all 0.5s;
            }
            .mask{
              display: none;
            }
          }
          img {
            width: 100%;
            height: 200px;
            object-fit: fill;
            transition: all 0.5s;
          }
        }

        .textA {
          width: 100%;

          text-align: center;
          margin-top: 8px;
          margin-left: 1px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
// 下面的盒子
.otherList {
  display: flex;
  flex-wrap: wrap;

  .otherItem {
    margin-top: 22px;
    // margin: 30px auto;
    width: 384px;
    height: 477px;
    border: 1px solid #cfcfcf;
    &:not(:nth-child(3n)) {
      margin-right: 20px;
    }
    .articleList {
      padding: 20px;
      .articleItem {
        margin-bottom: 15px;
        padding-bottom: 15px;
        &:not(:last-child) {
          border-bottom: 1px dashed #cfcfcf;
        }
        .articleNameLine {
          display: flex;
          align-items: center;
          .el-icon-arrow-right {
            font-size: 18px;
          }
          .name {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 10px;
            color: #333;
            font-size: 16px;
          }
        }
        .times {
          padding: 5px 0 0;
          color: #666;
          font-size: 15px;
          margin-left: 32px;
        }
      }
    }
  }
}
</style>
