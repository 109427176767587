<template>
  <div>
    <div class="TopLine">
      <div class="left">
        <img :src="$src+logoUrl" alt="" />
        <h2 v-if="title">{{ title||'暂无信息' }}</h2>
      </div>
      <div class="moreRight" @click="moreGo">更多>></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["logoUrl", "title","ids"],
  methods:{
    moreGo(){
      if(this.ids.outsideLink==1){
        window.open(this.ids.menuUrl)
      }else{
        this.$router.push({ path:'/home' ,query:{id:this.ids.id} }).catch(e=>e)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.TopLine {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  border-bottom: 2px solid #800000;
  .left {
    display: flex;
    align-items: center;
    img {
      width: 42px;
      height: 42px;
    }
    h2 {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 5px;
      font-size: 23px;
    }
  }
  .moreRight {
    cursor: pointer;
    font-size: 15px;
    color: #666;
  }
}
</style>