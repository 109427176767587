<template>
<div class="app">
  <div class="left">
    <div class="left_text">职代会提案</div>
    <div class="left_choose">
     <div @click="selectIndex(item)" :class="activeIndex == item.index ? 'left_choose_info actives':'left_choose_info'" v-for="(item,index) in textList" :key="index">
       {{item.text}}
     </div>
    </div>
    <div v-for="(item,index) in leftList" :key="index" v-if="token">
      <div :class="childId == item.id ? 'left_title active':'left_title'" @click="select(item,index)" >
        <div style="display: flex;justify-content: space-between">
          <div style="display:flex;">
            <div :class="childId == item.id ? 'left_border':'left_border_white'"></div>
            <div class="childId_info">{{item.proposalTitle||item.proposalContent}}</div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
        v-if="token"
        class="pagination"
        @current-change="currentChange"
        background
        layout="total,prev, pager, next"
        :total="total">
    </el-pagination>
  </div>
  <div class="right">
    <div class="right_activeTitle">
      <div>{{rightList.proposalTitle || '暂无标题'}}</div>
      <div class="right_address" v-if="token">
        <div @click="addShow = true">新增提案&nbsp;/&nbsp;</div>
        <div @click="out">退出登录</div>
      </div>
      <div class="right_address" v-else @click="dialogVisible = true">
        立即登录
      </div>
    </div>
    <div class="loding" v-if="!token">
      <img src="@/static/img/no.png">
      <div style="margin-top: 10px">暂无内容</div>
    </div>
    <div class="right_rich_list">
      <div class="secondUser"  v-if="token">
        <div>附议人员：{{rightList.secondUser?rightList.secondUser:'暂无'}}</div>
        <div style="color:rgba(0, 130, 251, 1)" v-if="rightList.examineStatus==0">待审核</div>
        <div style="color:rgba(255, 47, 49, 1);" v-if="rightList.examineStatus==2">已通过</div>
        <div style="color:rgb(150,142,142);" v-if="rightList.examineStatus==3">已驳回</div>
      </div>
      <el-link style="margin-left: 20px;margin-top: 20px" type="primary" @click="handleExport" v-if="rightList.fileName">
        附件【{{rightList.fileName}}】
      </el-link>
      <div class="proposalContent">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{rightList.proposalContent}}
      </div>
     <div class="picList" >
       <div class="pic" v-for="(item,index) in fileList" :key="index">
         <el-image
             class="img"
             :src="item"
             :preview-src-list="[item]">
         </el-image>
       </div>
     </div>
    </div>
    </div>
  <el-dialog
      :close-on-click-modal="false"
      title="欢迎登录"
      :visible.sync="dialogVisible"
      width="30%"
      center
      @close="handleClose"
      >
    <div class="login_dialog">
      <el-input placeholder="用户名" class="input" v-model="params.username">
        <template slot="prepend"><i class="el-icon-user"></i></template>
      </el-input>
      <el-input placeholder="密码" class="input" v-model="params.password" show-password>
        <template slot="prepend"><i class="el-icon-lock"></i></template>
      </el-input>
      <div class="yzm">
        <el-input placeholder="验证码" class="input2"  v-model="params.code">
          <template slot="prepend"><i class="el-icon-message"></i></template>
        </el-input>
        <img :src="'data:image/jpeg;base64,'+captchaImageList.img"/>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button style="width: 78%;margin-bottom: 20px" type="primary" @click="goToLogin">登录</el-button>
  </span>
  </el-dialog>

  <el-dialog
      :close-on-click-modal="false"
      title="新增提案"
      :destroy-on-close = "false"
      :visible.sync="addShow"
      width="40%"
      center
      @close="addHandleClose"
  >
    <div class="addShow">
      <div class="addShow_info">
        <div class="addShow_text">职代会标题：</div>
        <div style="width: 40%"> <el-input  placeholder="请输入职代会标题" class="input" v-model="addList.proposalTitle">
        </el-input></div>

      </div>
      <div class="addShow_info">
        <div class="addShow_text">提案内容：</div>
        <div style="width: 70%;">
          <el-input  type="textarea" placeholder="请输入职提案内容" class="input" v-model="addList.proposalContent">
          </el-input>
        </div>

      </div>
      <div class="addShow_info">
        <div class="addShow_text">附议人员：</div>
        <div style="width: 40%">
          <el-input  placeholder="请输入职附议人员" class="input" v-model="addList.secondUser">
          </el-input>
        </div>
      </div>
      <div class="addShow_info">
        <div class="addShow_text">图片：</div>
        <div style="width: 50%">
          <el-upload
              :headers="{'Authorization': 'Bearer ' + token}"
              class="upload-demo"
              action="/api/common/uploadOss"
              :on-remove="handleRemove"
              :on-success = "onSuccess"
              :on-exceed = "onExceed"
              :file-list="uploadFileList"
              :limit="5"
              accept=".jpg,.gif,.png,.jpeg"
              list-type="picture">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
      <div class="addShow_info">
        <div class="addShow_text">文件：</div>
        <div style="width: 50%">
          <el-upload
              :headers="{'Authorization': 'Bearer ' + token}"
              class="upload-demo"
              accept=".pdf,.doc,.docx,.xls,.xlsx"
              action="/api/common/uploadOss"
              :on-remove="handleRemove1"
              :on-success = "onSuccess1"
              :on-exceed = "onExceed1"
              :limit="1"
              :file-list="uploadFileList1">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>

      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button style="width: 30%;margin-bottom: 20px" type="primary" @click="sub">提交</el-button>
       <el-button style="width: 30%;margin-bottom: 20px;margin-left: 20px" type="info" @click="addShow = false">取消</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>

import {getCaptchaImage, getLogin,getAllianceProposalMyList,getAllianceProposalId,postAllianceProposal} from "@/api/api";
import axios from 'axios'
export default {
  name: "proposal",
  data(){
    return{
      uploadFileList:[],
      uploadFileList1:[],
      addList:{
        proposalTitle:'',
        proposalContent:'',
        secondUser:'',
        pic:'',
        files:'',
        fileName:''
      },
      addShow:false,
      activeIndex:1,
      textList:[{
        index:1,
        text:'全部'
      },{
        index:0,
        text:'待审核'
      },{
        index:2,
        text:'已通过'
      }],
      dialogVisible:false,
      total:19,
      rightList:{},
      childId:null,
      token:null,
      //传递后台的参数
      params: {
        code: "",
        password: "",
        username: "",
        uuid: ""
      },
      captchaImageList:{
        img:null
      },
      pageNum:1,
      pageSize:10,
      leftList:[],
      fileList:[],
    }
  },
  async created(){
    this.token = localStorage.getItem('token')
    this.getCaptcha()
    this.fileList = []
    if(!this.token){
      return
    }
    await this.getLeftList()
    if(this.childId == null){
      this.childId = this.leftList[0].id
    }
    await this.getRightInfo()

  },
  methods:{
    handleExport() {
      axios(this.rightList.files, {//pdf的位置
        responseType: 'blob', //重要代码
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = this.rightList.fileName //保存到本地的文件名称
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        console.log(link);
        link.remove();
      })
    },
    onExceed1(){
      this.$message({
        message: '最多上传1个文件~',
        type: 'warning'
      });
    },
    handleRemove1(file, fileList) {
      let _tmp = this.uploadFileList1;
      _tmp.forEach((item,index)=>{
        console.log(file.name,item,index)
        if (item.name == file.name) {
          _tmp.splice(index, 1);
        }
      })
      this.uploadFileList1 = _tmp;
    },
    onSuccess1(response, file, fileList){
      console.log(file,'file')
      this.uploadFileList1.push({
        name: file.name,
        url: file.response.fileName
      });
    },
    onExceed(){
      this.$message({
        message: '最多上传5张~',
        type: 'warning'
      });
    },
    handleRemove(file, fileList) {
      let _tmp = this.uploadFileList;
      _tmp.forEach((item,index)=>{
        console.log(file.name,item,index)
        if (item.name == file.name) {
          _tmp.splice(index, 1);
        }
      })
      this.uploadFileList = _tmp;
    },
    onSuccess(response, file, fileList){
      console.log(file,'file')
      this.uploadFileList.push({
        name: file.name,
        url: file.response.fileName
      });
    },
    sub(){
      console.log(this.uploadFileList,"uploadFileList")
      console.log(this.uploadFileList1)
      if (this.addList.proposalTitle == "") {
        this.$message({
          message: '请输入职代会标题',
          type: 'warning'
        });
        return
      }
      if (this.addList.proposalContent == "") {
        this.$message({
          message: '请填写提案内容',
          type: 'warning'
        });
        return
      }
      let arr = []
      this.uploadFileList.forEach(item=>{
        arr.push(item.url)
      })
      this.addList.pic = arr.join(",")
      if(this.uploadFileList1.length != 0){
        this.addList.files = this.uploadFileList1[0].url
        this.addList.fileName = this.uploadFileList1[0].name
      }
      console.log(this.addList)
      postAllianceProposal(this.addList).then(res=>{
        this.$message({
          message: '添加成功',
          type: 'success'
        });
        this.addShow = false
        this.pageNum = 1
        this.getLeftList()
      }).catch(err=>{

      })
    },
    out(){
      this.$confirm('确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('token')
        this.$message({
          message: '退出登录成功',
          type: 'success'
        });
        window.close()
      }).catch(() => {

      });

    },
    async select(item){
      this.childId = item.id
      await this.getRightInfo()
    },
    async selectIndex(item){
      if(!this.token){
        this.$message({
          message: '请先登录',
          type: 'warning'
        });
        return
      }
      this.activeIndex = item.index
      await this.getLeftList()
      this.childId = this.leftList[0].id
      await this.getRightInfo()
    },
    async getRightInfo(){
      await getAllianceProposalId(this.childId).then(res=>{
        this.rightList = res.data
        if(res.data.pic){
          this.fileList = []
          const fileList = res.data.pic.split(",")
          fileList.map(item=>{
            this.fileList.push(item)
          })
        }else {
          this.fileList = []
        }
      })
    },
    async getLeftList(){
      let param={
        pageNum:this.pageNum,
        pageSize:this.pageSize
      }
      if(this.activeIndex != 1){
        param.examineStatus = this.activeIndex
      }
      await getAllianceProposalMyList(param).then(res=>{
        this.leftList= res.rows
        this.total = res.total
      })
    },
    getCaptcha(){
      getCaptchaImage().then(res => {
        this.captchaImageList = res
      })
    },
    handleClose(){
      this.dialogVisible = false
    },
    addHandleClose(){
      this.addShow = false
    },
    async currentChange(e){
      this.pageNum = e
      await this.getLeftList()
      this.childId = this.leftList[0].id
      await this.getRightInfo()
    },
    goToLogin(){
      if (this.params.username == "") {
        this.$message({
          message: '请填写手机号码',
          type: 'warning'
        });
        this.getCaptcha()
        return
      }
      if (!(/^1[345789]\d{9}$/.test(this.params.username))) {
        this.$message({
          message: '手机号码格式不正确',
          type: 'warning'
        });
        this.getCaptcha()
        return
      }
      if(this.params.password == ""){
        this.$message({
          message: '请填写密码',
          type: 'warning'
        });
        this.getCaptcha()
        return
      }
      if(this.params.code == ""){
        this.$message({
          message: '请填写验证码',
          type: 'warning'
        });
        this.getCaptcha()
        return
      }
      this.params.uuid = this.captchaImageList.uuid
      getLogin(this.params).then(res=>{
        localStorage.setItem('token',res.token)
        this.$message({
          message: '登录成功',
          type: 'success'
        });
        location.reload()
        this.dialogVisible = false
      }).catch(err=>{
        this.isBtn = false
        this.getCaptcha()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.app{
  margin: 50px auto;
  width: 1200px;
  min-height: 500px;
  display: flex;
  .left{
    min-width: 278px;
    .left_choose{
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #8C8C8C;
      height: 50px;
      border-bottom: 1px solid #D4D4D4;
      font-size: 16px;
      background: #F7F7F7;
      .left_choose_info{
        cursor: pointer;
      }
      .left_choose_info:hover{
        color: #800000;
      }
      .actives{
        color: red;
      }
    }
    .left_text{
      height: 68px;
      line-height: 68px;
      padding-left: 40px;
      color: #fff;
      font-size: 24px;
      background: #800000;

    }
    .left_title{
      background: #F7F7F7;
      border-bottom: 1px solid #D4D4D4;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
    }
    .left_title:hover{
      color: #800000;
      cursor: pointer;
    }
    .childId_info{
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .active{
      border-bottom: 1px solid white;
      background: #FFFFFF;
    }
    .left_border{
      margin-right: 40px;
      width: 4px;
      background: #800000;
    }
    .left_border_white{
      margin-right: 40px;
      width: 4px;
      background: #F7F7F7;
    }
  }
}
.right{
  margin-left: 40px;
  width: 100%;
  .right_activeTitle{
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #D6D6D6;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    .right_address{
      display: flex;
      font-size: 15px;
      color: #9A9A9A;
      cursor: pointer;
      .right_address_info{
        display: flex;
      }
      .right_address_info_text{
        margin-left: 6px;
        margin-right: 6px;
      }
    }
    .right_address div:hover{
      color: #800000;
    }
  }
  .right_rich_list{
    .secondUser{
      margin-top: 20px;
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
    }
    .proposalContent{
      margin-top: 20px;
    }
    .picList{
      display: flex;
      flex-wrap: wrap;
      .pic{
        margin-top: 20px;
        margin-left: 20px;
        .img{
          max-width: 400px;
        }
      }
    }

  }
}
.pagination{
  margin-top: 60px;
  text-align: center;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #800000;
}

.right_rich_info:hover{
  background: #F6F6F6;
}
.login_dialog{
  width: 80%;
  margin: 0 auto;
  .input{
    width: 100%;
    margin-top: 20px;
  }
  .yzm{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .input2{
      width: 60%;
    }
    img{
      height: 40px;
      width: 35%;
    }
  }
}
.addShow{
  .addShow_info{
    display: flex;
    margin-bottom: 20px;
    .addShow_text{
      width: 100px;
    }
  }
  ::v-deep .el-textarea__inner{
    height: 200px;
  }
}
.loding{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
</style>
