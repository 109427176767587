<template>
  <div class="box">
    <div class="left">
      <div class="left_text">{{list.menuName}}</div>
      <div v-for="(item,index) in list.child" :key="index">
        <div :class="childId == item.id ? 'left_title active':'left_title'" @click="select(item,index)" >
          <div style="display: flex;justify-content: space-between">
           <div style="display:flex;">
             <div :class="childId == item.id ? 'left_border':'left_border_white'"></div>
              <div>{{item.menuName}}</div>
             </div>
            <div class="left_title_child" v-if="item.children">
              <i :class="!item.disabled ?'el-icon-arrow-right':'el-icon-arrow-down'"></i>
            </div>
          </div>
        </div>
        <div ref="box1" class="left_title_child_list" >
          <div :class="activeChildId == items.id ? 'child_title active2':'child_title'" v-for="(items,indexs) in item.children" :key="indexs" @click="selectChild(items)">
            {{items.menuName}}
          </div>
        </div>
      </div>

    </div>
    <div class="right">
      <div class="right_activeTitle">
       <div> {{activeText.menuName}}</div>
        <div class="right_address">
          <div>您的当前位置：</div>
          <div class="right_address_info" v-for="(item,index) in navigationList" :key="index">
            <div class="right_address_info_text">{{item.text}}</div>
            <div v-if="navigationList.length-1 != index">></div>
          </div>
        </div>
      </div>
      <div class="right_rich_list" v-if="rightList.length != 0 && !isShowTitle">
        <div class="right_rich_info" v-for="(item,index) in rightList" :key="index" @click="clickItem(item)">
          <div style="display: flex;align-items: center"><div style="width: 4px;height: 4px;border-radius: 50px;background: black;margin-right: 10px"></div><div class="right_rich_info_text">{{item.articleTitle}}</div></div>
          <div>{{item.createTime.slice(0,10)}}</div>
        </div>
        <el-pagination
            class="pagination"
            @current-change="currentChange"
            background
            layout="total,prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
      <div class="loding" v-if="rightList.length == 0">
        <img src="@/static/img/no.png">
        <div style="margin-top: 10px">暂无内容</div>
      </div>

<!--     内容 -->
      <div v-if="isShowTitle" class="right_rich_text">
        <div class="articleTitle">{{webTextInfo.articleTitle}}</div>
        <div class="createTime">
          <div>发布人：{{webTextInfo.sendNikeName}}</div>
          <div>日期：{{webTextInfo.createTime}}</div>
        </div>
       <div v-if="webTextInfo.articleType == 0" style="white-space: pre-wrap;" class="html1" v-html="webTextInfo.valueContent"></div>
        <div v-if="webTextInfo.articleType == 1" >
          <div v-for="(item,index) in webTextInfo.valueContent" class="video">
            <video :src="item" controls class="video_info"></video>
          </div>
        </div>
        <div v-if="webTextInfo.annexPreview == 1" style="margin-top: 10px">
          <el-link  type="primary" @click="handleExport">
           附件【{{webTextInfo.annexTitle+webTextInfo.annexText.substring(webTextInfo.annexText.lastIndexOf('.'))}}】
          </el-link>
        </div>
        <div v-if="webTextInfo.annexPreview == 1 && (webTextInfo.annexText.substring(webTextInfo.annexText.lastIndexOf('.')) == '.pdf')" style="margin-top: 10px">
          <iframe :src="pdf" style="width: 700px;height: 700px"></iframe>
        </div>
      </div>
      <div v-if="isShowTitle&&rightList.length!=1" class="back" ><span @click="back">返回</span></div>
    </div>
  </div>
</template>

<script>
import {getMenuListTree,getTabWebsiteMenuId,getWebsiteMenuList,getPublicTabWebsiteArticleId} from "@/api/api";
import axios from 'axios'
export default {
  name: "guild",
  data(){
    return{
      pdf:null,
      isShowTitle:false,
      isBtn:false,
      id:null,
      childId:null,
      navigationList:[
        {
          id:-1,
          text:'首页'
        },
      ],
      activeText:{},
      activeChildId:-1,
      webTextInfo:{},
      pageNum:1,
      pageSize:10,
      total:0,
      list:{},
      rightList:[]
    }
  },
  watch: {
    async $route(to, from) {
      if (this.$route.query.id) {
        //重新加载一次created或mounted钩子中的渲染的数据
        this.id = this.$route.query.id
        this.childId =  this.$route.query.childId
        this.isShowTitle = false
        this.pageNum = 1
        await this.getMuList()
        await this.addNav(this.list,1,2)
        await this.addNav(this.activeText,2,3)
        await this.getRightList(this.activeText.id)
      }
    },
  },
  async created(){
    this.id  = this.$route.query.id;
    this.childId =  this.$route.query.childId
    console.log(this.id,this.childId)
    await this.getMuList()
    await this.addNav(this.list,1)
    await this.addNav(this.activeText,2,3)
    await this.getRightList(this.activeText.id)
  },
  methods:{
    async back(){
      this.isShowTitle = false
      await this.getRightList(this.activeText.id)
    },
    handleExport() {
      let suffix = this.webTextInfo.annexText.substring(this.webTextInfo.annexText.lastIndexOf('.'))
      axios(this.webTextInfo.annexText, {//pdf的位置
        responseType: 'blob', //重要代码
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = this.webTextInfo.annexTitle + suffix //保存到本地的文件名称
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        console.log(link);
        link.remove();
      })
    },
    //获取文章详情
    async getRightInfo(id){
      await getPublicTabWebsiteArticleId(id).then(res=>{
        this.webTextInfo = res.data
        if(this.webTextInfo.articleType == 1){
          this.webTextInfo.valueContent =  this.webTextInfo.valueContent.split(",")
        }else if (this.webTextInfo.articleType == 2){
          window.open(this.webTextInfo.valueContent)
        }
        if(this.webTextInfo.annexPreview == 1 && (this.webTextInfo.annexText.substring(this.webTextInfo.annexText.lastIndexOf('.')) == '.pdf')){
          axios(this.webTextInfo.annexText, {//pdf的位置
            responseType: 'blob', //重要代码
          }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            this.pdf = url
          })
        }
        this.isShowTitle = true
      })
    },
    //分页
    async currentChange(e){
      this.pageNum = e
      await this.getRightList(this.activeText.id)
    },
    //获取右侧文章
    async getRightList(id){
      let params = {
        pageNum:this.pageNum,
        pageSize: this.pageSize,
        websiteMenuId:id
      }
      await getWebsiteMenuList(params).then(res=>{
        this.rightList = res.rows
        this.total = res.total
        if(this.rightList.length == 1 && this.pageNum ==1){
          this.getRightInfo(this.rightList[0].id)
        }else {
          this.webTextInfo = {}
        }
        this.isBtn = false
      }).catch(err=>{
        this.isBtn = false
      })
    },
    //获取菜单
    async getMuList(){
      let params = {
        supperId:this.id,
        pageSize:999,
        cloudAllianceId:localStorage.getItem('cloudAllianceId'),
        orderByColumn:'orderSort',
        isAsc:'desc'
      }
      await getTabWebsiteMenuId(this.id).then(res=>{
        console.log(res,"1111")
        this.list = res.data
      })
      if(this.childId){
        await getMenuListTree(params).then(res=>{
          this.list.child = res.rows
          //过滤
          const activeText = this.list.child.filter((item)=>{
            return item.id == this.childId
          })
          this.activeText = activeText[0]
          this.$forceUpdate()
          console.log(activeText,"ssss")
        })
      }
    },
    //添加右侧点击位置跳转的路径
    addNav(item,index,sum){
      console.log(item,'item')
      let obj
      if(index != 4){
        obj= {
          id:item.id,
          text:item.menuName
        }
      }else {
        obj = {
          id:item.id,
          text:'正文'
        }
      }
      if(index){
        this.navigationList.splice(index,1,obj)
      }
      if(sum){
        this.navigationList.splice(sum)
      }
    },
    clickItem(item){
      this.addNav(item,4)
      this.getRightInfo(item.id)
    },
    //点击左侧选项
    async select(item,index){

      if(this.isBtn){
        this.$message({
          showClose: true,
          message: '点击过快',
          type: 'warning'
        });
        return
      }
      this.childId = item.id
      const activeText = this.list.child.filter((item)=>{
        return item.id == this.childId
      })
      this.activeText = activeText[0]
      this.status = item.status
      this.activeChildId = -1
      if(item.children){
        item.disabled = !item.disabled
        this.$forceUpdate()
        if(item.disabled){
          setTimeout(()=>{
            console.log(this.$refs['box1'][index])
            this.$refs['box1'][index].style.height = this.$refs['box1'][index].scrollHeight + 'px'
          })
        }else {
          this.$refs['box1'][index].style.height = 0
        }
      }else {
        this.isBtn = true
        await this.getRightList(this.activeText.id)
        this.list.child.forEach(((item,indexs)=>{
          item.disabled = false
          this.$refs['box1'][indexs].style.height = 0
        }))
      }
      this.isShowTitle = false
      this.addNav(item,2,3)
    },
    //选择左侧子选项
    async selectChild(item){
      this.activeChildId = item.id
      this.activeText = item
      await this.getRightList(item.id)
      this.addNav(item,3)
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  margin: 50px auto;
  width: 1200px;
  min-height: 500px;
  display: flex;
  .left{
    min-width: 278px;
    .left_text{
      height: 68px;
      line-height: 68px;
      padding-left: 40px;
      color: #fff;
      font-size: 24px;
      background: #800000;

    }
    .left_title{
      background: #F7F7F7;
      border-bottom: 1px solid #D4D4D4;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
    }
    .left_title_child{
      margin-right: 16px;
    }
    .left_title_child_list{
      overflow: hidden;
      height: 0;
      transition: height 0.5s ease-out;
      .child_title{
        padding-left: 40px;
        background: #F6F6F6;
        border-bottom: 1px solid #D4D4D4;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
      }
      .child_title:hover{
        color: #800000;
        cursor: pointer;
      }
      .active2{
        color: #800000;
      }
    }
    .left_title:hover{
      color: #800000;
      cursor: pointer;
    }
    .active{
      border-bottom: 1px solid white;
      background: #FFFFFF;
    }
    .left_border{
      margin-right: 40px;
      width: 4px;
      background: #800000;
    }
    .left_border_white{
      margin-right: 40px;
      width: 4px;
      background: #F7F7F7;
    }
  }
}
.right{
  margin-left: 40px;
  width: 100%;
  .right_activeTitle{
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #D6D6D6;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    .right_address{
      display: flex;
      font-size: 15px;
      color: #9A9A9A;
      .right_address_info{
        display: flex;
      }
      .right_address_info_text{
        margin-left: 6px;
        margin-right: 6px;
      }

    }
  }
  .right_rich_text{
    margin-top: 30px;
    .html1{
      margin-top: 20px;
    }
    ::v-deep .html1 table{
      border-collapse: separate;
      border: 1px solid #ccc;
    }
    ::v-deep .html1 table th,::v-deep .html1 table td{
      border: 1px solid #ccc;
    }
    ::v-deep .html1 table td{
      padding: 0px 5px;
    }

    ::v-deep img{
      max-width: 100%;
    }
    .articleTitle{
      text-align: center;
      font-size: 28px;
    }
    .createTime{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      color: gray;
      div{
        margin-right: 10px;
      }
    }
  }
  .video{
    text-align: center;
    .video_info{
      margin-top: 20px;
      width: 500px;
    }
  }
  .right_rich_list{
    .right_rich_info{
      height: 50px;
      line-height: 50px;
      padding: 0 10px;
      font-size: 16px;
      border-bottom: 1px dotted #8C8C8C;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      .right_rich_info_text{

        max-width: 700px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .pagination{
      margin-top: 40px;
     text-align: center;
    }
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #800000;
    }

    .right_rich_info:hover{
      background: #F6F6F6;
    }

  }
}
.loding{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.back{
  text-align: right;
  margin-top: 20px;
  color: #999999;
  cursor: pointer;
}
.back>span:hover{
  color: #800000;
  cursor: pointer;
}
</style>
