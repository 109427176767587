<template>
  <div style="min-height: 500px;display: flex;justify-content: center;align-items: center;flex-direction: column">
    <h1 >404 Not Found</h1>
    <p>Sorry, the page you requested could not be found.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
